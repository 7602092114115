import React from 'react'
import "./AlertInfo.css";
import $ from "jquery";

export default function AlertInfo() {
    $(".close").click(function () {
        $(this).parent(".alert").fadeOut();
      });
  return (
    <div className="alert success-alert mt-4">
    <h3 className="alertInfo-text">
      <i className="bi bi-info-circle-fill text-dark mr-3"></i>
      &nbsp;Select dropdown Field(s) to show results!
    </h3>
    <a className="close">&times;</a>
  </div>
  )
}
