import React from "react";
import { useSelector } from "react-redux";
import { Navigate } from "react-router-dom";

const ProtectedRoute = ({ roleArg, children }) => {
  const state = useSelector((state) => state.stateVals);
  const { role, accessToken } = state;

  if (!accessToken || (roleArg && !roleArg.includes(role))) {
    return <Navigate to="/" />;
  }

  return children;
};

export default ProtectedRoute;
