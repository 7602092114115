import { useFormik } from "formik";
import React, { useEffect, useState } from "react";
import Select from "react-select";
import { updateCertiVlidation } from "../schema";
import CardService from "./Services/card.service";
import StateService from "./Services/state.service";

export default function ScanFormTest({
  initialValues,
  onSubmit,
  formDataIndex,
  onFormChange,
  website,
  vouchersError,
}) {
  const [StatesList, setStatesList] = useState([]);
  const [adminCards, setAdminCards] = useState([]);

  useEffect(() => {
    const listCards = async () => {
      try {
        const responce = await CardService.listCards(website);
        const res = responce.data.data;
        const results = [];
        res.map((value) => {
          return results.push({
            value: value.CardName.toString(),
            label: value.CardName.toString(),
          });
        });
        setAdminCards([...results]);
        onFormChange(formDataIndex, responce.data.data[0].CardName, "cardType");
        certiSearch.setFieldValue("cardType", responce.data.data[0].CardName);
      } catch (err) {
        console.log(err);
        setAdminCards([]);
      }
    };

    const listStates = async () => {
      const response = await StateService.getStateList(website);

      const res = response.data.response;
      const results = [];
      res.map((value) => {
        return results.push({
          value: value.code,
          label: value.state_name,
        });
      });
      setStatesList([...results]);
    };

    listCards();
    listStates();
  }, []);

  const changeRecieveMethod = (value, e) => {
    //alert(value);

    if (value === "Active") {
      e.target.style.background = "green";
    }
    if (value === "Voucher Mailed") {
      e.target.style.background = "purple";
    }
    if (value === "Voucher Received") {
      e.target.style.background = "SandyBrown";
    }
    if (value === "Card Ordered") {
      e.target.style.background = "pink";
    }
    if (value === "Card Mailed") {
      e.target.style.background = "SkyBlue";
    }
    if (value === "Expired") {
      e.target.style.background = "Salmon";
    }
    if (value === "Void") {
      e.target.style.background = "red";
    }
  };

  const certiSearch = useFormik({
    initialValues: { ...initialValues },
    validationSchema: updateCertiVlidation,
    onSubmit: onSubmit,
  });

  return (
    <div className="card search-certificate-result mt-3">
      <div className="card-header py-3">
        <h5 className="mb-0 font-22">Update Certificate</h5>
      </div>
      <div className="card-body ">
        <form
          className="row g-3"
          onSubmit={certiSearch.handleSubmit}
          noValidate
        >
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Certificate Number 1</label>
            <input
              type="text"
              className="form-control"
              placeholder="Certificate Number 1"
              name="certiNo"
              value={certiSearch.values.certiNo || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "certiNo");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.certiNo && certiSearch.touched.certiNo ? (
              <span className="text-danger">{certiSearch.errors.certiNo}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["certiNo"] &&
                  vouchersError["certiNo"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Customer Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Customer Name"
              name="custName"
              value={certiSearch.values.custName || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "custName");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.custName && certiSearch.touched.custName ? (
              <span className="text-danger">{certiSearch.errors.custName}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["custName"] &&
                  vouchersError["custName"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Business/NaVisions Number</label>
            <input
              type="text"
              className="form-control"
              aria-label="Disabled input example"
              disabled
              placeholder="Business/NaVisions Number"
              name="businNo"
              value={certiSearch.values.businNo || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "businNo");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.businNo && certiSearch.touched.businNo ? (
              <span className="text-danger">{certiSearch.errors.businNo}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["businNo"] &&
                  vouchersError["businNo"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Job Number</label>
            <input
              type="text"
              className="form-control "
              aria-label="Disabled input example"
              placeholder="Job Number"
              name="jobNo"
              value={certiSearch.values.jobNo || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "jobNo");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.jobNo && certiSearch.touched.jobNo ? (
              <span className="text-danger">{certiSearch.errors.jobNo}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["jobNo"] &&
                  vouchersError["jobNo"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Voucher Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="Voucher Number"
              name="vouchNo"
              value={certiSearch.values.vouchNo || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "vouchNo");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.vouchNo && certiSearch.touched.vouchNo ? (
              <span className="text-danger">{certiSearch.errors.vouchNo}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["vouchNo"] &&
                  vouchersError["vouchNo"].map((err) => err)}
              </span>
            ) : null}
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Denomination </label>
            <input
              type="text"
              className="form-control disabled"
              placeholder="Denomination"
              name="denom"
              value={certiSearch.values.denom || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "denom");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.denom && certiSearch.touched.denom ? (
              <span className="text-danger">{certiSearch.errors.denom}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["denom"] &&
                  vouchersError["denom"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">PA Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="PA Number"
              name="paNo"
              value={certiSearch.values.paNo || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "paNo");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.paNo && certiSearch.touched.paNo ? (
              <span className="text-danger">{certiSearch.errors.paNo}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["paNo"] &&
                  vouchersError["paNo"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Random Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="Random Number"
              name="randNo"
              value={certiSearch.values.randNo || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "randNo");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.randNo && certiSearch.touched.randNo ? (
              <span className="text-danger">{certiSearch.errors.randNo}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["randNo"] &&
                  vouchersError["randNo"].map((err) => err)}
              </span>
            ) : null}
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Client Cost</label>
            <input
              type="text"
              className="form-control"
              placeholder="Client Cost"
              name="clCost"
              value={certiSearch.values.clCost || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "clCost");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.clCost && certiSearch.touched.clCost ? (
              <span className="text-danger">{certiSearch.errors.clCost}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["clCost"] &&
                  vouchersError["clCost"].map((err) => err)}
              </span>
            ) : null}
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Redemption Period Begin</label>
            <input
              type="date"
              className="form-control "
              aria-label="Disabled input example"
              disabled
              placeholder="Redemption Period Begin"
              name="redemSt"
              value={certiSearch.values.redemSt || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "redemSt");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.redemSt && certiSearch.touched.redemSt ? (
              <span className="text-danger">{certiSearch.errors.redemSt}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["redemSt"] &&
                  vouchersError["redemSt"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Redemption Period End</label>
            <input
              type="date"
              className="form-control "
              aria-label="Disabled input example"
              disabled
              placeholder="Redemption Period End"
              name="redemEd"
              value={certiSearch.values.redemEd || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "redemEd");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.redemEd && certiSearch.touched.redemEd ? (
              <span className="text-danger">{certiSearch.errors.redemEd}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["redemEd"] &&
                  vouchersError["redemEd"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Compain ID No.</label>
            <input
              type="Compain ID No."
              className="form-control "
              aria-label="Disabled input example"
              disabled
              placeholder="Compain ID No."
              name="complnId"
              value={certiSearch.values.complnId || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "complnId");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.complnId && certiSearch.touched.complnId ? (
              <span className="text-danger">{certiSearch.errors.complnId}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["complnId"] &&
                  vouchersError["complnId"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Invoice No</label>
            <input
              type="number"
              className="form-control"
              placeholder="Invoice No"
              name="invcNo"
              value={certiSearch.values.invcNo || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "invcNo");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.invcNo && certiSearch.touched.invcNo ? (
              <span className="text-danger">{certiSearch.errors.invcNo}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["invcNo"] &&
                  vouchersError["invcNo"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Product Type</label>
            <input
              type="number"
              className="form-control "
              aria-label="Disabled input example"
              disabled
              placeholder="Product Type"
              name="prdType"
              value={certiSearch.values.prdType || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "prdType");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.prdType && certiSearch.touched.prdType ? (
              <span className="text-danger">{certiSearch.errors.prdType}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["prdType"] &&
                  vouchersError["prdType"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Redeemed On Date(m/d/y)</label>
            <input
              type="date"
              className="form-control"
              placeholder="Redeemed On Date(m/d/y)"
              name="RedeemedOnDate"
              value={certiSearch.values.RedeemedOnDate || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "RedeemedOnDate");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.RedeemedOnDate &&
            certiSearch.touched.RedeemedOnDate ? (
              <span className="text-danger">
                {certiSearch.errors.RedeemedOnDate}
              </span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["RedeemedOnDate"] &&
                  vouchersError["RedeemedOnDate"].map((err) => err)}
              </span>
            ) : null}
          </div>

          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">First Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="First Name"
              name="fName"
              value={certiSearch.values.fName || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "fName");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.fName && certiSearch.touched.fName ? (
              <span className="text-danger">{certiSearch.errors.fName}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["fName"] &&
                  vouchersError["fName"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Last Name</label>
            <input
              type="text"
              className="form-control"
              placeholder="Last Name"
              name="lName"
              value={certiSearch.values.lName || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "lName");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.lName && certiSearch.touched.lName ? (
              <span className="text-danger">{certiSearch.errors.lName}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["lName"] &&
                  vouchersError["lName"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Address</label>
            <input
              type="text"
              className="form-control"
              placeholder="Address"
              name="address"
              value={certiSearch.values.address || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "address");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.address && certiSearch.touched.address ? (
              <span className="text-danger">{certiSearch.errors.address}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["address"] &&
                  vouchersError["address"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">City</label>
            <input
              type="text"
              className="form-control"
              placeholder="City"
              name="city"
              value={certiSearch.values.city || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "city");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.city && certiSearch.touched.city ? (
              <span className="text-danger">{certiSearch.errors.city}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["city"] &&
                  vouchersError["city"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">State</label>
            {/* <select
          className="form-select dark-border"
          name="state"
          value={certiSearch.values.state || ""}
            onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "fName");
              }}
          onBlur={certiSearch.handleBlur}
        >
          <option value="">Select State</option>
          {StatesList.map((el, index) => {
            return (
              <option key={index} value={el.value}>
                {el.label}
              </option>
            );
          })}
        </select> */}
            {StatesList.length ? (
              <Select
                className="form-label dark-border"
                placeholder={
                  certiSearch.values.state !== ""
                    ? certiSearch.values.state
                    : "Select State"
                }
                options={StatesList}
                isSearchable={true}
                isClearable={true}
                name="state"
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    certiSearch.setFieldValue("state", selectedOption.value);
                    onFormChange(formDataIndex, selectedOption.value, "state");
                  } else {
                    certiSearch.setFieldValue("state", "");
                    onFormChange(formDataIndex, "", "state");
                  }
                }}
                onBlur={() => certiSearch.setFieldTouched("state", true)}
              />
            ) : null}

            {certiSearch.errors.state && certiSearch.touched.state ? (
              <span className="text-danger">{certiSearch.errors.state}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["state"] &&
                  vouchersError["state"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Zip</label>
            <input
              type="text"
              className="form-control"
              placeholder="Zip"
              name="zipCode"
              value={certiSearch.values.zipCode || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "zipCode");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.zipCode && certiSearch.touched.zipCode ? (
              <span className="text-danger">{certiSearch.errors.zipCode}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["zipCode"] &&
                  vouchersError["zipCode"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Phone Number</label>
            <input
              type="text"
              className="form-control"
              placeholder="Phone Number"
              name="phone"
              value={certiSearch.values.phone || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "phone");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.phone && certiSearch.touched.phone ? (
              <span className="text-danger">{certiSearch.errors.phone}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["phone"] &&
                  vouchersError["phone"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Email Address</label>
            <input
              type="email"
              className="form-control"
              placeholder="Email Address"
              name="email"
              value={certiSearch.values.email || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "email");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.email && certiSearch.touched.email ? (
              <span className="text-danger">{certiSearch.errors.email}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["email"] &&
                  vouchersError["email"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">
              Card Type (
              {certiSearch.values.cardApi !== ""
                ? certiSearch.values.cardApi
                : "N/A"}
              )
            </label>

            {adminCards.length ? (
              <Select
                className="form-label "
                placeholder={
                  certiSearch.values.cardType !== ""
                    ? certiSearch.values.cardType
                    : "Select Card Type"
                }
                options={adminCards}
                defaultValue={adminCards[0]}
                isSearchable={true}
                isClearable={true}
                onChange={(selectedOption) => {
                  if (selectedOption) {
                    certiSearch.setFieldValue("cardType", selectedOption.value);
                    onFormChange(
                      formDataIndex,
                      selectedOption.value,
                      "cardType"
                    );
                  } else {
                    certiSearch.setFieldValue("cardType", "");
                    onFormChange(formDataIndex, "", "cardType");
                  }
                }}
                onBlur={() => certiSearch.setFieldTouched("cardType", true)}
              />
            ) : null}

            {certiSearch.errors.cardType && certiSearch.touched.cardType ? (
              <span className="text-danger">{certiSearch.errors.cardType}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["cardType"] &&
                  vouchersError["cardType"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Certificate Status</label>

            <input
              type="text"
              className="form-control dark-border"
              disabled={true}
              onChange={(e) => {
                certiSearch.handleChange(e);
                changeRecieveMethod(e.target.value, e);
                onFormChange(formDataIndex, e.target.value, "certiStatus");
              }}
              name="certiStatus"
              value={certiSearch.values.certiStatus || ""}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.certiStatus &&
            certiSearch.touched.certiStatus ? (
              <span className="text-danger">
                {certiSearch.errors.certiStatus}
              </span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["certiStatus"] &&
                  vouchersError["certiStatus"].map((err) => err)}
              </span>
            ) : null}

            {/* <select
              id="ceckstatus"
              disabled={true}
              className="form-select dark-border"
              onChange={(e) => {
                certiSearch.handleChange(e);
                changeRecieveMethod(e.target.value, e);
                onFormChange(formDataIndex, e.target.value, "certiStatus");
              }}
              name="certiStatus"
              value={certiSearch.values.certiStatus || ""}
              onBlur={certiSearch.handleBlur}
            >
              <option value="" style={{ backgroundColor: "gray" }}>
                STATUS
              </option>
              <option value="Active" style={{ backgroundColor: "green" }}>
                Active
              </option>
              <option
                value="Voucher Mailed"
                style={{ backgroundColor: "purple" }}
              >
                Voucher Mailed
              </option>
              <option
                value="Voucher Received"
                style={{ backgroundColor: "SandyBrown" }}
              >
                Voucher Received
              </option>
              <option value="Card Ordered" style={{ backgroundColor: "pink" }}>
                Card Ordered
              </option>
              <option
                value="Card Mailed"
                style={{ backgroundColor: "SkyBlue" }}
              >
                Card Mailed
              </option>
              <option value="Expired" style={{ backgroundColor: "Salmon" }}>
                Expired
              </option>
              <option value="Void" style={{ backgroundColor: "red" }}>
                Void
              </option>
            </select> */}

            {certiSearch.errors.certiStatus &&
            certiSearch.touched.certiStatus ? (
              <span className="text-danger">
                {certiSearch.errors.certiStatus}
              </span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["certiStatus"] &&
                  vouchersError["certiStatus"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Status Date</label>
            <input
              type="date"
              className="form-control "
              aria-label="Disabled input example"
              disabled
              placeholder="Status Date"
              name="statusDate"
              value={certiSearch.values.statusDate || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "statusDate");
              }}
              onBlur={certiSearch.handleBlur}
            />
            {certiSearch.errors.statusDate && certiSearch.touched.statusDate ? (
              <span className="text-danger">
                {certiSearch.errors.statusDate}
              </span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["statusDate"] &&
                  vouchersError["statusDate"].map((err) => err)}
              </span>
            ) : null}
          </div>
          <div className="col-12 col-md-6 col-lg-4">
            <label className="form-label">Receive Method</label>
            <input
              type="text"
              className="form-control dark-border"
              disabled={true}
              onChange={(e) => {
                certiSearch.handleChange(e);
                changeRecieveMethod(e.target.value, e);
                onFormChange(formDataIndex, e.target.value, "recMeth");
              }}
              name="recMeth"
              value={certiSearch.values.recMeth || ""}
              onBlur={certiSearch.handleBlur}
            />
            {/* <select
              className="form-select dark-border"
              name="recMeth"
              disabled={true}
              value={certiSearch.values.recMeth || ""}
              onChange={async (e) => {
                certiSearch.handleChange(e);
                onFormChange(formDataIndex, e.target.value, "recMeth");
              }}
              onBlur={certiSearch.handleBlur}
            >
              <option value="">Select Receive Method</option>
              <option value="Mail">Mail</option>
              <option value="Phone">Phone</option>
              <option value="Web">Web</option>
            </select> */}
            {certiSearch.errors.recMeth && certiSearch.touched.recMeth ? (
              <span className="text-danger">{certiSearch.errors.recMeth}</span>
            ) : vouchersError ? (
              <span className="text-danger">
                {vouchersError["recMeth"] &&
                  vouchersError["recMeth"].map((err) => err)}
              </span>
            ) : null}
          </div>

          <div className="col-12 add-space-btns">
            <button type="submit" className="btn btn-primary w-100 mt-2">
              Update Certificate
            </button>
          </div>
        </form>
      </div>
    </div>
  );
}
