import React, { useEffect } from "react";
// import logo from './logo.svg';
import "./App.css";
import "react-toastify/dist/ReactToastify.css";
import Swal from "sweetalert2";

import { Route, Routes, useNavigate, useLocation } from "react-router-dom";

import Login from "./components/Login";
import Rsa from "./components/Rsa";
import { useDispatch, useSelector } from "react-redux";
import { bindActionCreators } from "redux";
import jwt_decode from "jwt-decode";
import { actionCreaters } from "./Redux";
import EmployeeTable from "./components/EmployeeTable";
import StoreTable from "./components/StoreTable";
import EditRsa from "./components/EditRsa";
import EditStore from "./components/EditStore";
import Store from "./components/Store";
import ProtectedRoute from "./components/Includes/ProtectedRoute";

import CardsTableUpdated from "./components/CardsTableUpdated";
// import ProtectedRoute from "./components/Includes/ProtectedRoute";

function App() {
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);
  const navigate = useNavigate();
  const locat = useLocation();
  const state = useSelector((state) => state.stateVals);
  // const { state, dispatch: ctxDispatch } = useContext(UserContext);
  const { accessToken, role } = state;
  // console.log(`role=${role}}`);
  let decoded;

  useEffect(() => {
    if (accessToken) {
      decoded = jwt_decode(accessToken);
      // auto logout code start
      if (Math.floor(new Date().getTime() / 1000) > decoded.exp) {
        userActions.logOut(null);
        Swal.fire({
          title: "Expired!",
          text: "Your Login Session expired!",
          icon: "error",
          confirmButtonText: "Login Again",
        });
        navigate("/");
      } else if (locat.pathname === "/") {
        if (role === "cards") {
          navigate("/list-cards");
        } else {
          navigate("/rsa");
        }
      }
    } else {
      navigate("/");
    }
  }, [locat.pathname]);

  // TODO websites management
  return (
    <Routes>
      <Route path="/" element={<Login />} />
      <Route
        path="/rsa"
        element={
          <ProtectedRoute roleArg={["admin"]}>
            <Rsa />
          </ProtectedRoute>
        }
      />
      <Route
        path="/list-employee"
        element={
          <ProtectedRoute roleArg={["admin"]}>
            <EmployeeTable />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-rsa/:id"
        element={
          <ProtectedRoute roleArg={["admin"]}>
            <EditRsa />
          </ProtectedRoute>
        }
      />
      <Route
        path="/store"
        element={
          <ProtectedRoute roleArg={["admin"]}>
            <Store />
          </ProtectedRoute>
        }
      />
      <Route
        path="/list-store"
        element={
          <ProtectedRoute roleArg={["admin"]}>
            <StoreTable />
          </ProtectedRoute>
        }
      />
      <Route
        path="/edit-store/:id"
        element={
          <ProtectedRoute roleArg={["admin"]}>
            <EditStore />
          </ProtectedRoute>
        }
      />

      <Route
        path="/list-cards"
        element={
          <ProtectedRoute roleArg={["cards"]}>
            <CardsTableUpdated />
          </ProtectedRoute>
        }
      />
      {/* <Route
        path="/list-cards-test"
        element={
          <ProtectedRoute roleArg={["cards"]}>
            <CardsTable />
          </ProtectedRoute>
        }
      /> */}

      {/* <Route path="/rsa" element={<Rsa />} /> */}
      {/* <Route path="/list-employee" element={<EmployeeTable />} /> */}
      {/* <Route path="/edit-rsa/:id" element={<EditRsa />} /> */}
      {/* <Route path="/store" element={<Store />} /> */}
      {/* <Route path="/list-store" element={<StoreTable />} /> */}
      {/* <Route path="/edit-store/:id" element={<EditStore />} /> */}
    </Routes>
  );
}

export default App;
