import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const getStoreList = (url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}/store/list/${url}`, {
    headers: headers,
  });
};

const addStore = (values, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (url == "cityfurnituretestrest.com" || url == "ashleysfltestrest.com") {
    return axios.post(
      `${API_URL}/store/add/${url}`,
      {
        store_name: values.store_name,
        store_location: values.store_location,
        regions: values.regions,
        location_code: values.location_code,
        director_name: values.director_name,
      },
      {
        headers: headers,
      }
    );
  } else {
    return axios.post(
      `${API_URL}/store/add/${url}`,
      {
        store_location: values.store_location,
        regions: values.regions,
      },
      {
        headers: headers,
      }
    );
  }
};

const updateStore = (id, values, url) => {
  console.log(`id=${id}, url=${url}`);
  console.log(values);
  const headers = {
    "Content-Type": "application/json",
  };
  if (url == "cityfurnituretestrest.com" || url == "ashleysfltestrest.com") {
    return axios.put(
      `${API_URL}/store/update/${id}/${url}`,
      {
        website: values.website,
        // store_name: values.store_name,
        // store_location: values.store_location,
        regions: values.regions,
        location_code: values.location_code,
        director_name: values.director_name,
      },
      {
        headers: headers,
      }
    );
  } else {
    return axios.put(
      `${API_URL}/store/update/${id}/${url}`,
      {
        website: values.website,
        // store_name: values.store_location,
        regions: values.regions,
      },
      {
        headers: headers,
      }
    );
  }
};

const getStoreById = (id, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}/store/get-by-id/${id}/${url}`, {
    headers: headers,
  });
};

const deleteStore = (id, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(`${API_URL}/store/delete/${id}/${url}`, {
    headers: headers,
  });
};


const StoreService = {
  getStoreList,
  getStoreById,
  addStore,
  updateStore,
  deleteStore,
};

export default StoreService;
