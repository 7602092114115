import React, { useEffect, useLayoutEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { signImSchema } from "../schema/index";
import UserService from "./Services/user.service";
import { useFormik } from "formik";
import { actionCreaters } from "../Redux/index";
import { bindActionCreators } from "redux";
import { useDispatch } from "react-redux/es/hooks/useDispatch";
import { toast, ToastContainer } from "react-toastify";
import { useSelector } from "react-redux";

function Login() {
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const userActions = bindActionCreators(actionCreaters, dispatch);


  

  const redirectTo = (role) => {
    console.log(role);
    role === "cards" ? navigate("/list-cards") : navigate("/rsa");
  };

  const login = async (values, action) => {
    setLoading(true);
    try {
      const response = await UserService.login(values);

      userActions.logIn({
        role: response.data.user.role,
        accessToken: response.data.access_token,
        id: response.data.user.id,
        uName: response.data.user.username,
        uType: response.data.user.user_type,
        name:
          response.data.user.first_name + " " + response.data.user.last_name,
      });
      action.resetForm();
      // navigate("/rsa");
      redirectTo(response.data.user.role);
      // FIXME prevent location reload

      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 401) {
        console.log("testing");
        setLoading(false);
        toast.error("Username or Password is invalid !", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      } else {
        setLoading(false);
        console.log(err);
        toast.error("Something went wrong, try again", {
          position: "top-right",
          autoClose: 3000,
          hideProgressBar: true,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
          theme: "colored",
        });
      }
    }
  };

  const { values, errors, touched, handleBlur, handleChange, handleSubmit } =
    useFormik({
      initialValues: {
        name: "",
        password: "",
      },
      validationSchema: signImSchema,
      onSubmit: (values, action) => {
        login(values, action);
      },
    });

  return (
    <>
      <div className="main-w3ls">
        <div className="left-content">
          <div className="overlay-left"></div>
          <div className="w3ls-content">
            <h1>
              <span className="fa fa-key"></span>Login to access this
            </h1>

            <h2>Employees Portal</h2>
            <a href="mailto:ashley@eliterewards.biz" className="button-w3ls">
              {/* <span className="fa fa-long-arrow-right"></span> */}
              <span className="fa fa-envelope" aria-hidden="true"></span>
            </a>
          </div>
        </div>

        <div className="right-form-agile">
          <div className="sub-main-w3 login">
            <ToastContainer />
            <h3>Login Request</h3>
            <h5></h5>
            <p></p>

            <form action="" onSubmit={handleSubmit}>
              <div className="form-style-agile">
                <label>Username</label>
                <div className="pom-agile">
                  <span className="fa fa-user"></span>
                  <input
                    type="text"
                    placeholder="Username"
                    value={values.name || ""}
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="name"
                    required=""
                  />
                </div>
                {errors.name && touched.name ? (
                  <p className="help is-danger">{errors.name}</p>
                ) : null}
              </div>
              <div className="form-style-agile">
                <label>Pasword</label>
                <div className="pom-agile">
                  <span className="fa fa-key"></span>
                  <input
                    type="password"
                    placeholder="Pasword"
                    onChange={handleChange}
                    onBlur={handleBlur}
                    name="password"
                    value={values.password || ""}
                    required=""
                  />
                </div>
                {errors.password && touched.password ? (
                  <p className="help is-danger">{errors.password}</p>
                ) : null}
              </div>

              <input type="submit" value="Login" />
            </form>
          </div>
        </div>
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}

export default Login;
