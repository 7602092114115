import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const getStateList = (url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}/states/list/${url}`, {
    headers: headers,
  });
};

const StateService = {
  getStateList,
};

export default StateService;
