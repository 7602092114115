import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const login = (values) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.post(
    API_URL + "/user/login",
    {
      username: values.name,
      password: values.password,
    },
    {
      headers: headers,
    }
  );
};

const UserService = {
    login,
  };
  
  export default UserService;
  