import React, { useLayoutEffect, useRef, useState } from "react";
import Banner from "./Includes/Banner";
import Navbar from "./Includes/Navbar";
import "./EmployeeTable.css";

import WebsitesService from "./Services/websites.service";

import AlertInfo from "./Includes/AlertInfo";
import Swal from "sweetalert2";

import Select from "react-select";

import CardService from "./Services/card.service";
import { ToastContainer, toast } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";
import moment from "moment";
import ScanFormTest from "./ScanFormTest";

export default function CardsTableUpdated() {
  let cmpName = "List Cards";

  const [disable, setDisable] = useState(true);

  const [loading, setLoading] = useState(false);
  const [scanDuplicate, setScanDuplicate] = useState(false);

  const [websites, setWebsites] = useState([]);

  const [showCertiRecord, setShowCertiRecord] = useState(false);

  const [vouchers, setVouchers] = useState([]);
  const [vouchersError, setVouchersError] = useState([]);

  const certRef = useRef();
  const selectedWebsite = useRef();

  const changeWebsite = (e) => {
    let value = e.value;
    certRef.current.value = "";

    selectedWebsite.current.value = value;
    if (value !== "") {
      setDisable(false);
      setTimeout(() => {
        certRef.current.focus();
      }, 200);
    } else {
      certRef.current.value = "";
      setDisable(true);
    }
  };

  const options = [];
  {
    websites.map((res) => {
      options.push({ value: res.url, label: res.name });
    });
  }

  const getWebsites = async () => {
    setLoading(true);
    try {
      const response = await WebsitesService.getRedemptionWebsitesList();

      setWebsites(response.data.response);
      setLoading(false);
    } catch (err) {
      if (err?.response?.status === 404) {
        setLoading(false);
      } else {
        setLoading(false);
      }
    }
  };

  useLayoutEffect(() => {
    getWebsites();
  }, []);

  const updateCertInfo = async (values) => {
    setLoading(true);

    try {
      const responce = await CardService.updateCertiUpdated(values);

      if (responce.status === 200) {
        setLoading(false);

        const updatedVouchers = vouchers.filter(
          (voucher) => voucher.id != values.id
        );

        setVouchers(updatedVouchers);
        setVouchersError([]);
        if (updatedVouchers.length) {
        } else {
          setShowCertiRecord(false);
        }
        Swal.fire({
          icon: "success",
          title: "Updated Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  const handleSubmit = (values) => {
    updateCertInfo(values);
  };

  const scan = async (e) => {
    if (e.target.value.length > 10) {
      if (vouchers?.length > 9) {
        Swal.fire({
          icon: "error",
          title: "Limit Exceed",
          iconColor: "#e04f5d",
          text: "Maximum 10 voucher can scan at once!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Ok",
        });
      } else {
        const foundObject = vouchers?.find(
          (item) => item?.certiNo === e.target.value
        );
        if (foundObject) {
          setScanDuplicate(true);
        } else {
          setLoading(true);
          setScanDuplicate(false);

          try {
            const responce = await CardService.getCertificateInfo(
              e.target.value,
              selectedWebsite.current.value
            );

            if (responce.status === 200) {
              setVouchers([
                ...vouchers,
                {
                  certiNo: responce?.data?.response?.certificate,
                  custName: responce?.data?.response?.customer,
                  businNo: responce?.data?.response?.na_visio,
                  jobNo: responce?.data?.response?.job,
                  vouchNo: responce?.data?.response?.voucher,
                  denom: responce?.data?.response?.denom,
                  paNo: responce?.data?.response?.pa_number,
                  randNo: responce?.data?.response?.random_certificate,
                  clCost: responce?.data?.response?.clientCost,
                  redemSt: responce?.data?.response?.redemptionBegins,
                  redemEd: responce?.data?.response?.redemptionEnds,
                  complnId: responce?.data?.response?.compaign,
                  invcNo: responce?.data?.response?.invoice,
                  prdType: responce?.data?.response?.product_type,
                  RedeemedOnDate:
                    responce?.data?.response?.redeemedDate !== "" &&
                    responce?.data?.response?.redeemedDate !==
                      "1000-10-10 00:00:00"
                      ? moment(responce?.data?.response?.redeemedDate).format(
                          "YYYY-MM-DD"
                        )
                      : "",
                  fName: responce?.data?.response?.fname,
                  lName: responce?.data?.response?.lname,
                  address: responce?.data?.response?.address,
                  city: responce?.data?.response?.city,
                  state: responce?.data?.response?.state,
                  zipCode: responce?.data?.response?.zip,
                  phone: responce?.data?.response?.phone,
                  email: responce?.data?.response?.email,
                  cardType: responce?.data?.response?.card,
                  cardApi: responce?.data?.response?.card,
                  certiStatus: responce?.data?.response?.certStatus,
                  statusDate: responce?.data?.response?.status_date,
                  recMeth: responce?.data?.response?.receiveMethod,

                  status: responce?.data?.response?.status,
                  id: responce?.data?.response?.id,
                  website: selectedWebsite.current.value,
                },
              ]);
              e.target.value = "";

              setShowCertiRecord(true);

              setLoading(false);
            }
          } catch (err) {
            console.log(err);

            setLoading(false);
            if (err?.response?.status === 400) {
              Swal.fire({
                icon: "error",
                title: "Expired",
                iconColor: "#e04f5d",
                text: err?.response?.data?.message,
                confirmButtonColor: "#e72e2e",
                confirmButtonText: "Scan Again",
              });
            } else if (
              err?.response?.data?.message &&
              typeof err?.response?.data?.message === "string"
            ) {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                iconColor: "#e04f5d",
                text: err?.response?.data?.message,
                confirmButtonColor: "#e72e2e",
                confirmButtonText: "Try Again",
              });
            } else {
              Swal.fire({
                icon: "error",
                title: "Oops...",
                iconColor: "#e04f5d",
                text: "Some thing went wrong!",
                confirmButtonColor: "#e72e2e",
                confirmButtonText: "Try Again",
              });
            }
          }
        }
      }
    }
  };

  const handleFormChange = (formDataIndex, val, fieldName) => {
    const updatedFormDataw = [...vouchers];

    updatedFormDataw[formDataIndex][fieldName] = val;

    setVouchers(updatedFormDataw);
  };

  const handleSubmitAllForms = async () => {
    function formatDate(dateString) {
      return moment(dateString).format("MM/DD/YYYY");
    }
    const formattedArray = vouchers.map((obj) => ({
      ...obj,
      RedeemedOnDate: formatDate(obj.RedeemedOnDate),
    }));

    try {
      setLoading(true);

      const responce = await CardService.updateCertiAllUpdated(
        selectedWebsite.current.value,
        formattedArray
      );

      if (responce.status === 200) {
        setLoading(false);
        setShowCertiRecord(false);
        setVouchersError([]);

        setVouchers([]);
        Swal.fire({
          icon: "success",
          title: "Updated Successfully!",
          showConfirmButton: false,
          timer: 1500,
        });
      }
    } catch (err) {
      console.log(err);
      setLoading(false);

      if (err?.response?.status === 422) {
        let res;

        res = err?.response?.data;

        setVouchersError(res);
      } else if (
        err?.response?.data?.message &&
        typeof err?.response?.data?.message === "string"
      ) {
        Swal.fire({
          icon: "error",
          title: "Error",
          iconColor: "#e04f5d",
          text: err?.response?.data?.message,
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      } else {
        Swal.fire({
          icon: "error",
          title: "Oops...",
          iconColor: "#e04f5d",
          text: "Some thing went wrong!",
          confirmButtonColor: "#e72e2e",
          confirmButtonText: "Try Again",
        });
      }
    }
  };

  return (
    <>
      <Navbar />
      <ToastContainer />
      <Banner cmpName={cmpName} />
      <div className="container">
        <form className="form-inline">
          <AlertInfo />
          <div className="row g-3">
            <div className="form-group col-md-6">
              <label className="title">Website</label>

              <Select
                options={options}
                onChange={changeWebsite}
                ref={selectedWebsite}
                isDisabled={vouchers.length}
              />
            </div>

            <div className="form-group col-md-6">
              <label className="title">Certificate</label>
              <input
                type="text"
                id="cert"
                placeholder="Enter Certificate"
                name="cert"
                maxLength={12}
                className="form-control"
                ref={certRef}
                disabled={disable}
                onChange={scan}
              />
              {scanDuplicate ? (
                <span className="text-danger">
                  Voucher already scanned and avaliable for update.
                </span>
              ) : null}
            </div>
          </div>
        </form>

        {showCertiRecord && (
          <>
            {vouchers.length &&
              vouchers.map((voucher, index) => (
                <ScanFormTest
                  website={selectedWebsite.current.value}
                  initialValues={voucher}
                  onSubmit={handleSubmit}
                  formDataIndex={index}
                  key={voucher.id}
                  onFormChange={handleFormChange}
                  vouchersError={vouchersError[index]}
                />
              ))}

            <div className="card search-certificate-result mt-3">
              <div className="card-body ">
                <button
                  type="submit"
                  className="btn btn-primary w-100 reverse-bg mt-0"
                  onClick={handleSubmitAllForms}
                >
                  Update All Certificate
                </button>
              </div>
            </div>
          </>
        )}
      </div>
      <div className={`loader ${loading ? "in" : ""}`}>
        <img
          src="https://res.cloudinary.com/sparkcloudsforewards/image/upload/v1692705802/loading_hosqnr.gif"
          alt="loading"
        />
      </div>
    </>
  );
}
