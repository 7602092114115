import axios from "axios";
import moment from "moment/moment";

const API_URL = process.env.REACT_APP_API_Link;

const getCertificateInfo = (cert, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}/card/certificate/${cert}/${url}`, {
    headers: headers,
  });
};

const redeemCard = (values, url) => {
  // console.log(values.certificate);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.put(
    `${API_URL}/card/redeem/${url}`,
    {
      certificate: values.certificate,
      fname: values.fname,
      lname: values.lname,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zip,
      phone: values.phone,
      email: values.email,
      card: values.card,
    },
    {
      headers: headers,
    }
  );
};

const listCards = (url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(
    `${API_URL}/card/list-cards/${url}`,
    {},
    {
      headers: headers,
    }
  );
};

const updateCerti = (values) => {
  return axios.put(
    API_URL + "/card/redeem/" + values.website,
    {
      certificate: values.certiNo,
      id: values.id,
      customer: values.custName,
      clientCost: values.clCost,
      random_certificate: values.randNo,
      voucher: values.vouchNo,
      job: values.jobNo,
      // redeem_date: values.RedeemedOnDate,
      redeem_date: moment(values.RedeemedOnDate).format("MM/DD/YYYY"),

      fname: values.fName,
      lname: values.lName,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zipCode,
      phone: values.phone,
      email: values.email,
      card: values.cardType,
      certificate_status: values.certiStatus,
      status_date: values.statusDate,
      receive_method: values.recMeth,
      denomination: values.denom,
      pa_number: values.paNo,
      invoice: values.invcNo,
    },
    {
      // headers: headers,
    }
  );
};

const updateCertiAll = (site, values) => {
  return axios.put(API_URL + "/card/redeem-multiple/" + site, values, {
    // headers: headers,
  });
};

const updateCertiUpdated = (values) => {
  return axios.put(
    API_URL + "/card/redeem/" + values.website,
    {
      certificate: values.certiNo,
      id: values.id,
      customer: values.custName,
      clientCost: values.clCost,
      random_certificate: values.randNo,
      voucher: values.vouchNo,
      job: values.jobNo,
      // redeem_date: values.RedeemedOnDate,
      redeem_date: moment(values.RedeemedOnDate).format("MM/DD/YYYY"),

      fname: values.fName,
      lname: values.lName,
      address: values.address,
      city: values.city,
      state: values.state,
      zip: values.zipCode,
      phone: values.phone,
      email: values.email,
      card: values.cardType,
      certificate_status: values.certiStatus,
      status_date: values.statusDate,
      receive_method: values.recMeth,
      denomination: values.denom,
      pa_number: values.paNo,
      invoice: values.invcNo,
    },
    {
      // headers: headers,
    }
  );
};

const updateCertiAllUpdated = (site, values) => {
  return axios.put(API_URL + "/card/redeem-multiple/" + site, values, {
    // headers: headers,
  });
};

const CardService = {
  getCertificateInfo,
  redeemCard,
  listCards,
  updateCerti,
  updateCertiAll,
  updateCertiUpdated,
  updateCertiAllUpdated,
};

export default CardService;
