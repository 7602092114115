import axios from "axios";

const API_URL = process.env.REACT_APP_API_Link;

const getRsaList = (store, url) => {
  console.log(url);
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}/rsa/list/${store}/${url}`, {
    headers: headers,
  });
};

const getRsaById = (id, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.get(`${API_URL}/rsa/get-by-id/${id}/${url}`, {
    headers: headers,
  });
};

// FIXME store_location, store_name city site
const addRsa = (values, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  if (url == "cityfurnituretestrest.com" || url == "ashleysfltestrest.com") {
    return axios.post(
      `${API_URL}/rsa/add/${url}`,
      {
        email: values.email,
        fname: values.fname,
        lname: values.lname,
        employee_id: values.employee_id,
        store_name: values.store_location,
      },
      {
        headers: headers,
      }
    );
  } else {
    return axios.post(
      `${API_URL}/rsa/add/${url}`,
      {
        email: values.email,
        fname: values.fname,
        lname: values.lname,
        employee_id: values.employee_id,
        store_location: values.store_location,
      },
      {
        headers: headers,
      }
    );
  }
};

// FIXME store_location, store_name city site
const updateRsa = (id, values, url) => {
  console.log(`id=${id}, url=${url}`);
  console.log(values);
  const headers = {
    "Content-Type": "application/json",
  };
  if (url == "cityfurnituretestrest.com" || url == "ashleysfltestrest.com") {
    return axios.put(
      `${API_URL}/rsa/update/${id}/${url}`,
      {
        email: values.email,
        fname: values.fname,
        lname: values.lname,
        employee_id: values.employee_id,
        store_name: values.store_location,
      },
      {
        headers: headers,
      }
    );
  } else {
    return axios.put(
      `${API_URL}/rsa/update/${id}/${url}`,
      {
        email: values.email,
        fname: values.fname,
        lname: values.lname,
        employee_id: values.employee_id,
        store_location: values.store_location,
      },
      {
        headers: headers,
      }
    );
  }
};

const deleteRsa = (id, url) => {
  const headers = {
    "Content-Type": "application/json",
  };
  return axios.delete(`${API_URL}/rsa/delete/${id}/${url}`, {
    headers: headers,
  });
};

const RsaService = {
  getRsaList,
  getRsaById,
  addRsa,
  deleteRsa,
  updateRsa,
};

export default RsaService;
