import * as Yup from "yup";

export const signImSchema = Yup.object({
  name: Yup.string()
    .min(2, "User Name must greater than 2")
    .max(50, "Length exceeds")
    .required("Please enter the User Name"),
  password: Yup.string()
    .min(2, "Password must greater than 2")
    .max(100, "Length exceeds")
    .required("Please enter the Password"),
});
export const rsaSchema = Yup.object({
  website: Yup.string().required("Please select the Website"),
  email: Yup.string().email().required("Please enter the Email"),
  store_location: Yup.string().required("Please select the Store Location"),
  rsa_name: Yup.string().required("Please enter the Rsa Full Name"),
  employee_id: Yup.string().required("Please enter the Employee Id Number"),
});

// TODO conditional sites
export const storeSchema = Yup.object({
  website: Yup.string().required("Please select the Website"),
  store_location: Yup.string().required("Please select the Store Location"),
  regions: Yup.string().required("Please enter the Region"),
});

// TODO conditional sites
export const updateStoreSchema = Yup.object({
  website: Yup.string().required("Please select the Website"),
  // store_location: Yup.string().required("Please select the Store Location"),
  regions: Yup.string().required("Please enter the Region"),
});

export const cardsSchema = Yup.object({
  fname: Yup.string().required("Please enter First Name"),
  lname: Yup.string().required("Please enter Last Name"),
  address: Yup.string().required("Please enter Address"),
  city: Yup.string().required("Please enter City"),
  state: Yup.string().required("Please enter State"),
  zip: Yup.string().required("Please enter Zip Code"),
  email: Yup.string().email().required("Please enter Email"),
  phone: Yup.string().required("Please enter Phone"),
  // card: Yup.string().required("Please select Card"),
});

export const updateCertiVlidation = Yup.object({
  jobNo: Yup.string()
    .min(5, "Please enter 5 or 6 digit number")
    .max(6, "Please enter 5 or 6 digit number")
    .required("Please enter job number"),
  certiNo: Yup.string().required("Please enter certificate number 1"),

  custName: Yup.string().required("Please enter customer name"),
  fName: Yup.string().required("Please enter first name"),
  lName: Yup.string().required("Please enter last name"),
  address: Yup.string().when("website", {
    is: (val) =>
      val === "bca-rewards.com" ||
      val === "bloodassurancerewards.com" ||
      val === "selectyourcard.biz" ||
      val === "beta.bca-rewards.com",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Please enter address"),
  }),

  phone: Yup.string()
    .matches(/^[0-9 -]+$/, "Invalid phone number")
    .when("website", {
      is: (val) =>
        val === "bca-rewards.com" ||
        val === "bloodassurancerewards.com" ||
        val === "selectyourcard.biz" ||
        val === "beta.bca-rewards.com",
      then: Yup.string().notRequired(),
      otherwise: Yup.string().required("Please enter phone"),
    }),

  zipCode: Yup.string().when("website", {
    is: (val) =>
      val === "bca-rewards.com" ||
      val === "bloodassurancerewards.com" ||
      val === "selectyourcard.biz" ||
      val === "beta.bca-rewards.com",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Please enter zip"),
  }),

  state: Yup.string().when("website", {
    is: (val) =>
      val === "bca-rewards.com" ||
      val === "bloodassurancerewards.com" ||
      val === "selectyourcard.biz" ||
      val === "beta.bca-rewards.com",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Please select state"),
  }),

  city: Yup.string().when("website", {
    is: (val) =>
      val === "bca-rewards.com" ||
      val === "bloodassurancerewards.com" ||
      val === "selectyourcard.biz" ||
      val === "beta.bca-rewards.com",
    then: Yup.string().notRequired(),
    otherwise: Yup.string().required("Please enter city"),
  }),

  email: Yup.string().email().required("Please enter email"),
  certiStatus: Yup.string().required("Please enter certificate status"),
});
